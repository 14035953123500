import { paramCount } from "./constants"

export default function HeaderParams(props) {

    return (
        <div id="header" className='params'>
            <p>Headers:</p>
            <table className="table is-scrollable">
                <thead>
                    <tr>
                        <th><abbr title="key">Key</abbr></th>
                        <th><abbr title="value">Value</abbr></th>
                    </tr>
                </thead>
                <tbody>
                    {paramCount.flatMap((num) => (
                        <tr key={num} >
                            <td><input id={"header-key-" + num} className="input header-items" type="text" placeholder="header-key" /></td>
                            <td><input id={"header-value-" + num} className="input header-items" type="text" placeholder="header-value" /></td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}