export default function Modal(props) {

    return (
        <div id="progress-modal" className="modal">
            <div className="modal-background"></div>
            <div className="modal-content">
                <progress id="progress-bar" className="progress" value="10" max="100"></progress>
            </div>
            <button className="modal-close is-large" aria-label="close"></button>
        </div>
    );
}