export default function Auth(props) {

    return (
        <div>
            <div id="auth">
                <p id="auth-label">Authorization</p>
                <div className="control">
                <label className="auth-radio">
                        <input className="auth-input" id="ajax" type="radio" name="answer" checked></input>
                        Ajax
                    </label>
                        <label className="auth-radio">
                        <input className="auth-input" id="cookie" type="radio" name="answer"></input>
                        Browser Session
                    </label>
                    <label className="auth-radio">
                        <input className="auth-input" id="token" type="radio" name="answer"></input>
                        Custom
                    </label>
                </div>
            </div>
        </div>
    );
}