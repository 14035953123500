import { copyToClipBoard } from './util';

export default function Response(props) {

    return (
        <div className="response">
            <textarea id="response-code" placeholder="Status: " disabled></textarea>
            <button id="response-copy" className='button is-info' onClick={() => copyToClipBoard('response-body')}>Copy Response Body</button>
            <textarea id="response-body" className="textarea request-items" placeholder="Response Body"></textarea>
        </div >
    );
}