import Auth from "./Auth";
import { fetchdata } from "./util";
export default function MethodURL(props) {
    return (
        <div id="method-url">
            <div className="select request-items">
                <select id="method">
                    <option>GET</option>
                    <option>POST</option>
                    <option>PUT</option>
                    <option>PATCH</option>
                    <option>DELETE</option>
                </select>
            </div>
            <input id="url" className="input is-primary request-items" type="text" placeholder="e.g. gateway.mindsphere.io/api/v1/iot" />
            <button id="submit" className='button is-primary request-items' onClick={fetchdata}>Send</button>
        </div>
    );
}
