import './App.css';
import 'bulma/css/bulma.css'
import QueryParams from './comp/QueryParams';
import HeaderParams from './comp/HeaderParams';
import PageHeader from './comp/PageHeader';
import MethodURL from './comp/MethodURL';
import Auth from './comp/Auth';
import Response from './comp/Response';
import Modal from './comp/Modal';

function App() {
  return (
    <div className="App">
      <PageHeader></PageHeader>
      <div id="div-center">
        <MethodURL></MethodURL>
        <Auth></Auth>
        <div id="param-header">
          <QueryParams></QueryParams>
          <HeaderParams></HeaderParams>
        </div>
        <textarea id="request-body" className="textarea request-items" placeholder="Request Body"></textarea>
        <Response></Response>
        <Modal></Modal>
      </div>
    </div >
  );
}

export default App;
