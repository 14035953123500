import { paramCount, url,tokenurl,authvurl } from "./constants";
export const getCookies = () => {
    let cookie = document.cookie;
    let cookies = cookie.split(';');
    let ret = '';
    for (let i = 1; i <= cookies.length; i++) {
        ret += i + ' - ' + cookies[i - 1] + "\n";
    }
    return ret;
}
export const getQueryParams = () => {
    let params = {}
    for (let i = 1; i < paramCount.length; i++) {
        let key = document.getElementById("query-key-" + i).value;
        let val = document.getElementById("query-value-" + i).value;
        if (key !== null && key !== '' && val !== null && val !== '') {
            params[key] = val
        }
    }
    return params
}

export const getHeaderParams = () => {
    let params = {}
    for (let i = 1; i < paramCount.length; i++) {
        let key = document.getElementById("header-key-" + i).value;
        let val = document.getElementById("header-value-" + i).value;
        if (key !== null && key !== '' && val !== null && val !== '') {
            if(key==='CustomAuthorization'){
                key='Authorization'
                params[key] = val
            }
            if(key==='Authorization' && document.getElementById("token").checked){
                console.log("Skip")
            }
            else {
                params[key] = val
            }
        }
    }
    let key='kong-debug'
    params[key] = '1'
    return params
}

export const appendCookies = function (headerParams) {
    var pairs = document.cookie.split(";");
    for (var i = 0; i < pairs.length; i++) {
        var pair = pairs[i].split("=");
        let key = (pair[0] + '').trim();
        let val = pair.slice(1)
        if (key !== null && key !== '' && val !== null && val !== '') {
            headerParams[(key).trim()] = (val.join('='));
        }
    }
    return headerParams;
}

export const copyToClipBoard = function CopyToClipboard(id) {
    var copyText = document.getElementById(id);

    copyText.select();
    copyText.setSelectionRange(0, 99999); // For mobile devices

    navigator.clipboard.writeText(copyText.value);
}

const showModalProgressBar = () => {
    document.getElementById("progress-modal").classList.add("is-active");
    let updateProgressbar = setInterval(() => {
        document.getElementById("progress-bar").value === 100 ?
            document.getElementById("progress-bar").value = 0 :
            document.getElementById("progress-bar").value += 1;
    }, 5);
    return updateProgressbar;
}
console.log(getCookies());
const hideModalProgressBar = (updateProgressbar) => {
    clearInterval(updateProgressbar);
    document.getElementById("progress-bar").value = 100;
    document.getElementById("progress-modal").classList.remove("is-active");
    document.getElementById("progress-bar").value = 0;
}

export const fetchdata = async () => {
    //let pbar = showModalProgressBar();
    let queryParams = getQueryParams();
    let headerParams = getHeaderParams();

    let params = {}

    let response,resp;
    try{
    if (document.readyState == "complete") {
if (document.getElementById("cookie").checked) {
    headerParams = getHeaderParams();
    console.log("Browser session call")
    let data = {
        url: document.getElementById("url").value,
        method: document.getElementById("method").value,
        query: queryParams,
        header: JSON.stringify(headerParams),
        reqbody: document.getElementById("request-body").value
    }
    if(data.method==="GET"){
        data = {
            url: document.getElementById("url").value,
            method: document.getElementById("method").value,
            header: JSON.stringify(headerParams),
        }
    }
        response = await fetch(authvurl, {
            method: "POST",
            mode: "cors",
            body: JSON.stringify(data),
            });
        }
    

if (document.getElementById("ajax").checked) {
    response = '';
    console.log("Ajax call")
    headerParams = getHeaderParams();
      
    let data = {
        url: document.getElementById("url").value,
        method: document.getElementById("method").value,
        auth: document.getElementById("ajax")?.checked ? "token" : "cookie",
        query: queryParams,
        header: JSON.stringify(headerParams),
        reqbody: document.getElementById("request-body").value
    }
    if(data.method==="GET"){
        console.log("Inside Get call")
    response = await fetch(data.url, {
        header:data.header,
        method: data.method,
        query: data.query
    });
    console.log("response"+response);
    }
    else{
        console.log("Inside Else call")
        response = await fetch(data.url, {
        header:data.header,
        method: data.method,
        query: data.query,
        body: data.reqbody });
    }
    console.log("response"+response)
}
if(document.getElementById("token").checked) {
    headerParams = getHeaderParams();
    console.log("Custom") 
    console.log(headerParams);
    let data;
    if(document.getElementById("method").value==="GET"){
    data = {
        url: document.getElementById("url").value,
        method: document.getElementById("method").value,
        query: queryParams,
        header: JSON.stringify(headerParams),
    }
    response = await fetch(url, {
        method: "POST",
        mode: "cors",
            body: JSON.stringify(data),
        });
    } 
    else {
    headerParams = getHeaderParams();
    data = {
        url: document.getElementById("url").value,
        method: document.getElementById("method").value,
        query: queryParams,
        header: JSON.stringify(headerParams),
        reqbody: document.getElementById("request-body").value
    }
    response = await fetch(url, {
        method: "POST",
        mode: "cors",
            body: JSON.stringify(data),
        });
}


}
    
   //console.log("req data - ", data)
   console.log("Inside resp",response);
   try{
    if(response.headers.get('Content-Type').includes("text/plain")){
        resp = await response.text();
        document.getElementById("response-code").textContent = "Status: " + JSON.stringify(resp.status);
        document.getElementById("response-body").value = resp;
    }
    else{
        resp = await response.json();
        document.getElementById("response-code").textContent = "Status: " + JSON.stringify(resp.status);
        document.getElementById("response-body").value = JSON.stringify(resp, null, '\t');
    }
} catch(error){
        console.log(resp);
   
   }

}
    //hideModalProgressBar(pbar);
} catch(error){}  
}
async function getTextFromStream(readableStream) {
    let reader = readableStream.getReader();
    let utf8Decoder = new TextDecoder();
    let nextChunk;
        let resultStr = '';
        while (!(nextChunk = await reader.read()).done) {
        let partialData = nextChunk.value;
        resultStr += utf8Decoder.decode(partialData);
    }
    
    return resultStr;
}
