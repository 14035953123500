import { paramCount } from "./constants"

export default function QueryParams(props) {

    return (
        <div id="query" className='params'>
            <p>Params:</p>
            <table className="table is-scrollable">
                <thead>
                    <tr>
                        <th><abbr title="key">Key</abbr></th>
                        <th><abbr title="value">Value</abbr></th>
                    </tr>
                </thead>
                <tbody>
                    {paramCount.flatMap((num) => (
                        <tr key={num} >
                            <td><input id={"query-key-" + num} className="input query-items" type="text" placeholder="query-key" /></td>
                            <td><input id={"query-value-" + num} className="input query-items" type="text" placeholder="query-value" /></td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div >
    );
}